import * as React from "react";
import { Divider, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import { Link, graphql } from "gatsby";
import { facetOptions, facetOptionsFromPlural } from "../util/facetOptions";
import CountriesTableData from "../components/CountriesTableData";
import Layout from "../components/Layout";

const mapGridItems = ([field, fieldValues]: [any, any], idx: number): JSX.Element => (
  <Grid item xs={12} md={6} lg={2} key={idx.toString()}>
    <Typography sx={{ mt: 4, mb: 2 }} variant="display3" component="div">
      {field}
    </Typography>
    <List dense={true}>
      {fieldValues.map((fieldValue: any) => (
        <ListItem key={`${field}/${fieldValue}`} component="div">
          <Link to={`/${facetOptionsFromPlural(field)?.urlKey}/${fieldValue}`}>
            <ListItemText primary={fieldValue} />
          </Link>
        </ListItem>
      ))}
    </List>
  </Grid>
);

const FacetDetails: React.FC<any> = ({ data }) => {
  const facetOption = (facetOptions as any)[data.facetDetails.field];
  const isNotCountryFaceting = data?.facetDetails?.field !== "application_country";

  return (
    <Layout title={data.facetDetails.fieldValue}>
      <Typography
        variant="display2"
        color="#757575"
        sx={{ textTransform: "uppercase" }}
        gutterBottom
        component="div"
      >
        <Link to={`/${facetOption.urlKey}`}>{facetOption.displayName}: </Link>
        {data.facetDetails.field === "application_country"
          ? data.facetDetails.countries[0].fullName
          : data.facetDetails.fieldValue.toUpperCase()}
      </Typography>
      <Divider />
      <Grid container spacing={2}>
        {Object.entries(data.facetDetails)
          .filter(([key]) => !["field", "fieldValue"].includes(key))
          .filter(([key]) => key !== facetOption.plural)
          .filter(([key]) => key !== "countries")
          .map(mapGridItems)}
        {isNotCountryFaceting && <CountriesTableData countries={data.facetDetails.countries} />}
      </Grid>
    </Layout>
  );
};

export const query = graphql`
  query FacetDetails($key: FacetInput!, $value: String!) {
    facetDetails(key: $key, value: $value) {
      countries {
        shortName
        fullName
        geo
      }
      events
      field
      fieldValue
      platforms
      tags
      vendors
      views
    }
  }
`;
export default FacetDetails;
