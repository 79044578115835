import * as React from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { Link } from "gatsby";

interface CountriesTableDataProps {
  countries: CountryDetail[];
}

interface CountryDetail {
  shortName: string;
  geo: string;
  fullName: string;
}

const CountriesTableData: React.FC<CountriesTableDataProps> = ({ countries }) => {
  const getCountryList = (details: CountryDetail[], geo: string) =>
    details
      .filter((detail) => detail?.geo === geo)
      .sort((a, b) => (a.fullName > b.fullName ? 1 : -1))
      .map((fieldValue: any, key: number) => (
        <ul key={key}>
          <li>
            <Link to={`/country/${fieldValue.shortName}`}>{fieldValue.fullName}</Link>
          </li>
        </ul>
      ));

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Typography sx={{ mt: 4, mb: 2 }} variant="display3" component="div">
        countries
      </Typography>
      <TableContainer component={Paper} sx={{ boxShadow: "none", overflowX: "visible" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right">APLA</TableCell>
              <TableCell align="right">EMEA</TableCell>
              <TableCell align="right">Greater China</TableCell>
              <TableCell align="right">North America</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="right" sx={{ verticalAlign: "top" }}>
                {getCountryList(countries, "APLA")}
              </TableCell>
              <TableCell align="right" sx={{ verticalAlign: "top" }}>
                {getCountryList(countries, "EMEA")}
              </TableCell>
              <TableCell align="right" sx={{ verticalAlign: "top" }}>
                {getCountryList(countries, "GC")}
              </TableCell>
              <TableCell align="right" sx={{ verticalAlign: "top" }}>
                {getCountryList(countries, "NA")}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default CountriesTableData;
